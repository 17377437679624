<template>
	<div class="container">
		<el-card>
			<div class="flexRow" style="justify-content: space-between;">
				<div>自动营销模板</div>
				<el-link type="info" style="color: #666;" href="https://jusnn6k8al.feishu.cn/docs/doccnWJd6cUm2YbmjXwDRKNQE5c"
					target="_blank">
					<i class="el-icon-tickets" style="margin-right: 3px;"></i>自动营销功能使用说明
				</el-link>
			</div>
			<div class="flexRow" style="margin-top:15px;">
				<labeltem ispush @click="clickcreated"></labeltem>
				<div class="flexRow" style="overflow:hidden;overflow-x:auto;flex:1">
					<div class="flexRow">
						<labeltem v-for="(v,i) in labellist" :key="i" :message="v" @click="jumplabel(v)"></labeltem>
					</div>
					<div class = "bluelabel" style="cursor: pointer;flex-shrink:0" @click="$router.push({name: 'marketadvice'})">
						<span>全部模板</span>
						<i class = "el-icon-right"></i>
					</div>
				</div>
			</div>
		</el-card>
		<el-card style="margin-top:10px">
			<div class="search">
				<div class="search" style="margin-right: 150px;">
					<div class="search-item">
						<span class="search-title">关键字:</span>
						<el-input placeholder="任务名称" v-model="keywords" style="width: 200px;margin-right: 10px;"></el-input>
					</div>
					<div class="search-item">
						<span class="search-title">推送规则:</span>
						<el-select v-model="updatestyle" placeholder="请选择" style="width: 200px;margin-right: 5px;">
							<el-option v-for="item in updatestylelist" :key="item.value" :label="item.lable" :value="item.value">
							</el-option>
						</el-select>
					</div>
					<div class="search-item">
						<span class="search-title">任务状态:</span>
						<el-select v-model="state" placeholder="请选择" style="width: 200px;margin-right: 5px;">
							<el-option v-for="item in stateList" :key="item.value" :label="item.lable" :value="item.value">
							</el-option>
						</el-select>
					</div>
					<div class="search-item">
						<div>
							<el-button type="primary" style="margin-right: 15px;" @click="pageIndex=1;getList()">查询
							</el-button>
						</div>
						<!-- <div>
							<el-button type="primary" @click="handleToDetail(null)">添加规则</el-button>
						</div> -->
					</div>
				</div>
			</div>


			<el-table :data="tableData" style="width: 100%" v-loading="loading">
				<el-table-column prop="Name" label="任务名称">
					<template slot-scope="scope">
						<div class="rule-name">{{scope.row.Name}}</div>
					</template>
				</el-table-column>
				<el-table-column prop="Channel" label="推送渠道">
					<template slot-scope="scope">
						<!-- <el-popover title="" placement="right" trigger="hover">
							<div style="max-height: 400px;max-width: 400px;overflow: auto;">{{scope.row.Channel}}</div> -->
							<div class="rule-detail">{{scope.row.Channel}}</div>
						<!-- </el-popover> -->
					</template>
				</el-table-column>
				<el-table-column prop="PushRuleTypeValue" label="推送规则">
					<!-- <template slot-scope="scope">
						<el-popover title="" width="400" placement="right" trigger="hover">
							<div style="display: inline-block;max-height: 400px;overflow: auto;">
								<el-tag style="margin-right: 5px;margin-bottom: 5px;" type="info"
									v-for="(item, index) in scope.row.TagRuleConfigTagDetailList" :key="index">
									{{item.TagDetailName}}
								</el-tag>
							</div>
							
							<span slot="reference">
								<el-tag style="margin-right: 5px;margin-bottom: 5px;" type="info"
									v-for="(item, index) in scope.row.TagRuleConfigTagDetailList" :key="index"
									v-if="index<2">
									{{item.TagDetailName}}
								</el-tag>
								<el-tag type="info" v-if="scope.row.TagRuleConfigTagDetailList.length>2">...</el-tag>
							</span>
						</el-popover>
					</template> -->
				</el-table-column>
				<el-table-column prop="StateValue" label="状态"></el-table-column>
				<el-table-column prop="LastPushTime" label="最近一次推送时间">
					<template slot-scope="scope">
						<div>{{scope.row.LastPushTime?scope.row.LastPushTime:'--'}}</div>
					</template>
				</el-table-column>
				<el-table-column prop="AddTime" label="创建时间">
				</el-table-column>
				<el-table-column prop="control" label="操作" width="200">
					<template slot-scope="scope">
						<el-button type="text" @click="edit(scope.row)" v-if="scope.row.State == 1">编辑</el-button>
						<el-button type="text" @click="look(scope.row)" v-if="scope.row.State == 2">查看</el-button>
						<el-button type="text"  @click="todata(scope.row)" v-if="scope.row.LastPushTime">数据</el-button>
						<!-- v-if="scope.row.LastPushTime" -->
						<buttonPermissions :datas="'automarketcopy'" style="margin-left:10px">
							<el-button type="text" @click="copy(scope.row)">复制</el-button>
						</buttonPermissions>
						<buttonPermissions :datas="'automarketdel'">
							<el-button type="text" style="color:#f56c6c;margin-left:10px" v-if="scope.row.State == 2" @click="del(scope.row)">删除</el-button>
						</buttonPermissions>
						<buttonPermissions :datas="'automarketstop'">
							<el-button type="text" style="color:#f56c6c;margin-left:10px" v-if="scope.row.State == 1" @click="stop(scope.row)">终止</el-button>
						</buttonPermissions>
					</template>
				</el-table-column>
			</el-table>
			<div class="pagination" v-if="total">
				<div class="left">
				</div>
				<el-pagination :page-size="pageSize" :total="total" :current-page="pageIndex"
					:page-sizes="[10, 20, 30, 40, 50]" @size-change="sizeChange" @current-change="currentChange"
					layout="total, sizes, prev, pager, next, jumper">
				</el-pagination>
			</div>

		</el-card>

    <dialoglabel :visible="labelshow" @closelabel="labelshow=false"></dialoglabel>
	</div>
</template>

<script>
	import buttonPermissions from '@/components/buttonPermissions';
	import {
		autoMarketingTaskConfiglist,
		autoMarketingTaskConfigdelete,
		autoMarketingTaskConfigmodifyState
	} from '@/api/sv3.0.0.js';
	import labeltem from "../components/labeltem"
  import dialoglabel from "../components/dialoglabel"
	import marketcompon from '../marketcompon'
	export default {
		components: {
			labeltem,
      dialoglabel,
			buttonPermissions
		},
		data() {
			return {
				keywords: '',
				stateList: [
					{value:null,lable:'全部'},
          {value:1,lable:'进行中'},
          {value:2,lable:'已结束'},
				],
				state: null,
				updatestyle:null,
				updatestylelist:[{
						lable: "全部",
						value: null
					},
					{
						lable: "定时仅推送一次",
						value: 1
					},
					{
						lable: "周期循环",
						value: 2
					},
					{
						lable: "客户行为触发",
						value: 3
					},
				],
				tableData: [],
				pageIndex: 1,
				pageSize: 20,
				total: 0,
				loading: false,

        labelshow:false,

				labellist:[],
			};
		},
		created(){
			this.getlabellist()
			// console.log(this.$route.query.TagDetailId)
		},
		beforeMount() {
			this.getList()
		},
		methods: {
			getlabellist(){
				this.labellist = []
				marketcompon.map((v)=>{
					if([4,1,9,6].includes(v.templateId)){
						this.labellist.push(v)
					}
				})
			},
			jumplabel(v){
				this.$router.push({
        name: 'configuration',
        query: {
          templateId: v.templateId
        }
      })
			},
			todata(e){
				this.$router.push({
					path:'/smartmarket/automarket/data',
					query:{
						Id:e.Id
					}
				})
			},
			btncreat(){
				this.$router.push({
					path:'/smartmarket/automarket/configuration',
					// query:{
					// 	Id:e.Id
					// }
				})
			},
      clickcreated(){
        this.labelshow = true
      },
			async getList() {
				this.loading = true;
				try {
					const res = await autoMarketingTaskConfiglist({
						Skip: (this.pageIndex - 1) * this.pageSize,
						Take: this.pageSize,
						TagDetailId:this.$route.query.TagDetailId?this.$route.query.TagDetailId:'',
						Keywords: this.keywords,
						State: this.state,
						PushRuleType:this.updatestyle
					})

					if (res.IsSuccess) {
						this.tableData = res.Result.Results;
						this.total = res.Result.Total;
					}
				} catch (e) {
					
				}finally{
					this.loading = false;
				}
			},
			sizeChange(value) {
				this.pageSize = value;
				this.getList();
			},
			currentChange(index) {
				this.pageIndex = index;
				this.getList()
			},
			stop(e){
				this.$confirm(`是否确认终止自动营销任务[${e.Name}] ？任务终止后，无论客户是否满足目标推送客户群，将不再执行推送。若当前有正在执行的推送不受影响`, '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning',
					confirmButtonClass:'btnclass',
					cancelButtonClass:'btnclass',
				}).then(() => {
					this.tostop(e)
				}).catch(() => {})
			},
			async tostop(e){
				this.loading = true
				try{
					let msg = await e
					let data = {
						AutoMarketingTaskConfigId:msg.Id,
						State:2
					}
					let result = await autoMarketingTaskConfigmodifyState(data)
					if(result.IsSuccess){
						this.$message.success('操作成功')
						this.getList()
					}
				}finally{
					this.loading = false
				}
			},
			del(e){
				this.$confirm(`是否确认删除自动营销任务[${e.Name}]？删除后不可恢复。`, '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning',
					confirmButtonClass:'btnclass',
					cancelButtonClass:'btnclass',
				}).then(() => {
					this.todel(e)
				}).catch(() => {})
			},
			async todel(e){
				this.loading = true
				try{
					let data = {
						AutoMarketingTaskConfigId:await e.Id
					}
					let result = await autoMarketingTaskConfigdelete(data)
					if(result.IsSuccess){
						this.$message.success('操作成功')
						this.getList()
					}
				}finally{
					this.loading = false
				}
			},
			copy(e){
				this.$router.push({
					path:'/smartmarket/automarket/configuration',
					query:{
						Id:e.Id,
						copy:true
					}
				})
			},
			look(e){
				this.$router.push({
					path:'/smartmarket/automarket/configuration',
					query:{
						Id:e.Id,
					}
				})
			},
			edit(e){
				this.$router.push({
					path:'/smartmarket/automarket/configuration',
					query:{
						Id:e.Id,
					}
				})
			},
		}
	}
</script>

<style lang="less" scoped>
	.container {
		font-size: 14px;

		.search {
			position: relative;
			margin-bottom: 15px;

			display: flex;
			flex-wrap: wrap;

			.search-item {
				// margin-bottom: 10px;
				margin-right: 15px;
				display: flex;

				.search-title {
					padding-top: 10px;
					margin-right: 5px;
					font-size: 14px;
					font-weight: bold;
					color: #606266;
				}
			}

			.help {
				position: absolute;
				right: 0;
				top: 5px;
				white-space: nowrap;
				color: #666;
				cursor: pointer;
			}
		}

		.rule-detail {
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		}
		.rule-name{
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		}

		.pagination {
			display: flex;
			margin-top: 15px;
			justify-content: space-between;
			align-items: center;
		}
	}
	.flexRow{
		display: flex;
		flex-direction: row;
		align-items: center;
		// justify-content: space-between;
	}
	.bluelabel{
		width: 130px;
		height: 180px;
		// margin-top:10px;
		background: #409eff;
		border-radius: 3px;
		// margin-left:15px;
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
		color: white;
	}
</style>
<style>
	/* .btnclass{
		 font-size: 14px; 
	} */
</style>
