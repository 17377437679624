<template>
  <div>
    <el-dialog :visible="visible" custom-class="label-body" @close="closelabel" @closed="closed" width="960px">
      <div slot="title" class="header-title">选择模板</div>
      <div style="max-height:700px;overflow:hidden;overflow-y:auto">
        <div>
          <div style="margin:10px 0px 10px 0px">
            <div class = "classitytitle">不使用模板，自定义创建 <span>不使用模板，自定义本次自动营销任务的推送人群</span> </div>
            <labeltem ispush @click="creattemplate"></labeltem>  
          </div>
          <div>
            <div class = "titfont">
              <span>新客转化</span>
              <span class = "secfont">针对从没有在商城里支付成功过的客户进行交易转化</span>
            </div>
            <div class = "flexRow" style="flex-wrap:wrap">
              <labeltem v-for="(x,y) in marketlist1" :key="y" :message="x" @click="jumpautomarket(x)"></labeltem>
            </div>
          </div>
          <div>
            <div class = "titfont">
              <span>老客促活</span>
              <span class = "secfont">针对商城里的老客户提高活跃度，提升复购等</span>
            </div>
            <div class = "flexRow" style="flex-wrap:wrap">
              <labeltem v-for="(x,y) in marketlist2" :key="y" :message="x" @click="jumpautomarket(x)"></labeltem>
            </div>
          </div>
          <div>
            <div class = "titfont">
              <span>客户忠诚与关怀</span>
              <span class = "secfont">针对商城里的会员或意向会员进行忠诚度培养、日常关怀问候等，以保持与客户间的联系</span>
            </div>
            <div class = "flexRow" style="flex-wrap:wrap">
              <labeltem v-for="(x,y) in marketlist3" :key="y" :message="x" @click="jumpautomarket(x)"></labeltem>
            </div>
          </div>
          <div>
            <div class = "titfont">
              <span>客户社交层级</span>
              <span class = "secfont">针对商城里喜欢分享的客户，进行推手转化，或者针对商城现有推手进行升级引导</span>
            </div>
            <div class = "flexRow" style="flex-wrap:wrap">
              <labeltem v-for="(x,y) in marketlist4" :key="y" :message="x" @click="jumpautomarket(x)"></labeltem>
            </div>
          </div>
          <div>
            <div class = "titfont">
              <span>商品成交与复购</span>
              <span class = "secfont">针对商品，对商品有意向或购买过商品的客户，进行成交转化，或复购提升等</span>
            </div>
            <div class = "flexRow" style="flex-wrap:wrap">
              <labeltem v-for="(x,y) in marketlist5" :key="y" :message="x" @click="jumpautomarket(x)"></labeltem>
            </div>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import labeltem from "./labeltem"
import marketcompon from '../marketcompon'
export default {
  components: {
    labeltem
  },
  props: {
    visible:{
      type:Boolean,
      default:false,
    },

  },
  data () {
    return {
      activeName:'1',
      marketlist1:[],
      marketlist2:[],
      marketlist3:[],
      marketlist4:[],
      marketlist5:[],
    }
  },
  created () {
    this.getlist()
  },
  methods: {
    jumpautomarket(e){
      this.$router.push({
        name: 'configuration',
        query: {
          templateId: e.templateId
        }
      })
    },
    getlist(){
      this.marketlist1 = []
      this.marketlist2 = []
      this.marketlist3 = []
      this.marketlist4 = []
      this.marketlist5 = []
      marketcompon.map((v)=>{
        if([1,2,3].includes(v.templateId)){
          this.marketlist1.push(v)
        }
        if([4,5,6,7].includes(v.templateId)){
          this.marketlist2.push(v)
        }
        if([8,9,10,11,12,13].includes(v.templateId)){
          this.marketlist3.push(v)
        }
        if([14,15,16,17,18].includes(v.templateId)){
          this.marketlist4.push(v)
        }
        if([19,20,21,22,23].includes(v.templateId)){
          this.marketlist5.push(v)
        }
      })
    },
    creattemplate(){
      this.$router.push({
        path:'/smartmarket/automarket/configuration'
      })
      // console.log(1)
    },
    handleClick(e){
      this.activeName = e.name
      // console.log(e.name)
    },
    closelabel(){
      this.$emit('closelabel')
    },
    closed(){
      this.activeName = '1'
    },
  }
}
</script>

<style lang = "less" scoped>
.flexRow{
  display: flex;
  flex-direction: row;
  align-items: center;
}
.titfont{
    font-size: 16px;
    color: #606266;
    white-space: nowrap;
    font-weight: bold;
    margin: 15px 0px 5px 10px;
    /* width: 25%; */
    .secfont{
      font-size: 14px;
      color: #999999;
      font-weight: 100;
      margin:0px 0px 0px 20px;
    }
  }
  .nolist{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* border:1px solid black; */
    padding: 150px 0px;
    .firstfont{
      font-weight: bold;
      font-size: 18px;
      color: #303133;
    }
    .secondfont{
      font-size: 14px;
      color: 606266;
      margin-top: 20px;
    }
    .thirdfont{
      font-size: 16px;
      color: 606266;
      margin-top:5px;
    }
  }
  .havelist{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  .classitytitle{
    font-weight: bold;
    font-size: 16px;
    color: #606266;
    margin:0px 0px 10px 10px;
    span{
      margin-left:15px;
      font-size: 14px;
      color: #999999;
      font-weight: 100;
    }
  }
  .header-title{
		font-size: 18px;
		border-bottom: 1px solid #eee;
		padding-bottom: 20px;
		padding-top: 20px;
		padding-left: 20px;
		box-shadow: 0 0px 10px 0px rgba(0,0,0,.1);
		margin-left: -20px;
		margin-top: -20px;
		margin-right: -20px;
	}
</style>
<style>
  .label-body .el-dialog__body{
    padding: 0px 20px;
  }
</style>